/* Global styles for the <drkcln/> brand */

/* Import custom font - replace with actual font path if you have one */
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;300;400;500;700&display=swap');

/* Custom scrollbar */
.drkcln-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #000;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #00ff00;
    border-radius: 0;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #00cc00;
  }
}

/* Custom cursor styles */
.cursor-matrix {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%2300ff00' d='M11.5,1 L11.5,4 M8,2.5 L15,2.5 M7,11.5 L17,11.5 M11.5,7 L11.5,16 M4.5,4.5 L19.5,19.5 M19.5,4.5 L4.5,19.5'/%3E%3C/svg%3E") 12 12, auto;
}

/* Selection styles */
::selection {
  background: rgba(0, 255, 0, 0.2);
  color: #00ff00;
}

/* Focus styles */
:focus {
  outline: 2px solid #00ff00;
  outline-offset: 2px;
}

/* Custom animations */
@keyframes matrix-flicker {
  0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
    opacity: 1;
  }
  20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
    opacity: 0.33;
  }
}

.matrix-flicker {
  animation: matrix-flicker 6s infinite;
}

/* Custom colors for text highlight/selection */
::selection {
  background-color: rgba(0, 255, 0, 0.2);
  color: #00ff00;
}

/* Specific style for brand pages */
.drkcln-page {
  background-color: #000;
  color: #00ff00;
  font-family: 'JetBrains Mono', monospace;
  
  /* Apply matrix cursor only on brand pages */
  &.cursor-matrix {
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath stroke='%2300ff00' stroke-width='1' fill='none' d='M11.5,1 L11.5,4 M8,2.5 L15,2.5 M7,11.5 L17,11.5 M11.5,7 L11.5,16 M4.5,4.5 L19.5,19.5 M19.5,4.5 L4.5,19.5'/%3E%3C/svg%3E") 12 12, auto;
  }
}