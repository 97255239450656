// Matrix-inspired styles for the <drkcln/> brand page

// Glitch text effect
.glitch-text {
  position: relative;
  animation: glitch 3s infinite;
  
  &::before,
  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip: rect(0, 0, 0, 0);
  }
  
  &::before {
    left: 2px;
    text-shadow: -1px 0 #00ff00;
    animation: glitch-effect 3s infinite linear alternate-reverse;
  }
  
  &::after {
    left: -2px;
    text-shadow: 1px 0 #ff00ff;
    animation: glitch-effect 2s infinite linear alternate;
  }
}

.glitch-effect {
  position: relative;
  
  &::before,
  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip: rect(0, 0, 0, 0);
  }
  
  &::before {
    left: 2px;
    text-shadow: -1px 0 #00ff00;
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim-1 5s infinite linear alternate-reverse;
  }
  
  &::after {
    left: -2px;
    text-shadow: 1px 0 #ff00ff;
    clip: rect(24px, 450px, 36px, 0);
    animation: glitch-anim-2 5s infinite linear alternate;
  }
}

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 #00ff00, -0.05em -0.025em 0 #ff00ff;
  }
  14% {
    text-shadow: 0.05em 0 0 #00ff00, -0.05em -0.025em 0 #ff00ff;
  }
  15% {
    text-shadow: -0.05em -0.025em 0 #00ff00, 0.025em 0.025em 0 #ff00ff;
  }
  49% {
    text-shadow: -0.05em -0.025em 0 #00ff00, 0.025em 0.025em 0 #ff00ff;
  }
  50% {
    text-shadow: 0.025em 0.05em 0 #00ff00, 0.05em 0 0 #ff00ff;
  }
  99% {
    text-shadow: 0.025em 0.05em 0 #00ff00, 0.05em 0 0 #ff00ff;
  }
  100% {
    text-shadow: -0.025em 0 0 #00ff00, -0.025em -0.025em 0 #ff00ff;
  }
}

@keyframes glitch-anim-1 {
  0% {
    clip: rect(34px, 450px, 65px, 0);
  }
  20% {
    clip: rect(44px, 450px, 14px, 0);
  }
  40% {
    clip: rect(5px, 450px, 32px, 0);
  }
  60% {
    clip: rect(72px, 450px, 83px, 0);
  }
  80% {
    clip: rect(15px, 450px, 66px, 0);
  }
  100% {
    clip: rect(24px, 450px, 17px, 0);
  }
}

@keyframes glitch-anim-2 {
  0% {
    clip: rect(12px, 450px, 43px, 0);
  }
  20% {
    clip: rect(65px, 450px, 54px, 0);
  }
  40% {
    clip: rect(23px, 450px, 10px, 0);
  }
  60% {
    clip: rect(54px, 450px, 76px, 0);
  }
  80% {
    clip: rect(31px, 450px, 21px, 0);
  }
  100% {
    clip: rect(6px, 450px, 39px, 0);
  }
}

@keyframes glitch-effect {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-5px, 5px);
  }
  40% {
    transform: translate(-5px, -5px);
  }
  60% {
    transform: translate(5px, 5px);
  }
  80% {
    transform: translate(5px, -5px);
  }
  100% {
    transform: translate(0);
  }
}

// Additional scanline effect
.scanline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: rgba(0, 255, 0, 0.2);
  opacity: 0.6;
  pointer-events: none;
  animation: scanlines 2s linear infinite;
}

@keyframes scanlines {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100vh);
  }
}

// VHS static noise background
.vhs-static {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  opacity: 0.05;
  pointer-events: none;
  z-index: 1000;
}